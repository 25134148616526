<template>
  <div>
    <TripList
      :fstId="`riderTrips_${getId}`"
      :endpoint="getEndpoint"
      :qso="getEndpointOptions"
    />
  </div>
</template>

<script>
import TripList from '@/composites/trip/index/Index.vue'
import { useEndpoints } from '@/composables'

export default {
  name: 'ViewRiderUserTrips',

  components: {
    TripList,
  },

  computed: {
    getId() {
      return this.$route.params.id
    },
    getEndpoint() {
      return useEndpoints.trip.index()
    },
    getEndpointOptions() {
      return {
        append: `&user=${this.$route.params.id}`,
        prepend: `?`,
      }
    },
  },
}
</script>

<style lang="scss"></style>
